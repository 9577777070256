var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadcrumb',{attrs:{"home":false,"breadcrumbs":[
      {
        label: '制程管理',
        to: '/workflow',
      },
      {
        label: '详情',
        to: ("/workflow/detail?workflowId=" + (this.$route.query.workflowId) + "&groupId=" + (this.$route.query.groupId)),
      } ]}}),_c('el-card',{staticClass:"card-common",scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("基本信息")]},proxy:true}])},[_c('InfoPanel',{attrs:{"schema":_vm.workflowSchema,"data":_vm.workflow},scopedSlots:_vm._u([{key:"name",fn:function(ref){
    var value = ref.value;
return [_c('div',[_vm._v(_vm._s(value))]),_c('el-link',{staticClass:"edit-btn",attrs:{"type":"primary"},on:{"click":_vm.onEdit}},[_vm._v(" 编辑 ")])]}}])}),_c('el-dialog',{attrs:{"title":"编辑","visible":_vm.editDialogVisible,"width":"30%"},on:{"update:visible":function($event){_vm.editDialogVisible=$event}}},[_c('el-form',{attrs:{"model":_vm.factory,"label-width":"80px"}},[_c('el-form-item',{staticStyle:{"margin":"0"},attrs:{"label":"请输入制程名称"}},[_c('el-input',{model:{value:(_vm.workflow.name),callback:function ($$v) {_vm.$set(_vm.workflow, "name", $$v)},expression:"workflow.name"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onEditSubmit}},[_vm._v("提交")])],1)],1)],1),_c('el-card',{staticClass:"card-common",scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("步骤管理")]},proxy:true}])},[_c('div',{staticClass:"process-wall"},[_c('el-row',{attrs:{"type":"flex","gutter":20}},[_c('el-col',{staticClass:"vertical-dividing-line",staticStyle:{"padding-right":"0px"},attrs:{"span":6}},[_c('ProcessActived',{attrs:{"groupId":_vm.selectGroupId,"workflowId":_vm.selectWorkflowId},on:{"processClicked":_vm.onProcessClicked,"processDataLen":_vm.onProcessDataLen}})],1),_c('el-col',{attrs:{"span":18}},[(_vm.processDataLen > 0)?_c('div',{staticStyle:{"padding":"30px"}},[_c('Procedure',{attrs:{"processId":_vm.selectProcessId,"workflowId":_vm.selectWorkflowId,"groupId":_vm.selectGroupId}})],1):_c('div',{staticClass:"empty-data"},[_c('span',[_vm._v("暂无数据")])])])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }