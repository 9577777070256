<template>
  <div>
    <div class="process-manage">
      <div class="process-manage-header">
        <span style="line-height: 40px; font: bold">全部步骤</span>
        <el-button
          style="float: right; margin-left: 10px"
          type="text"
          @click="onAddProcess()"
          ><i class="el-icon-circle-plus-outline" />新增</el-button
        >
        <el-button
          style="float: right"
          type="text"
          @click="onVersionSelectedDialog(true)"
          ><i class="el-icon-s-operation" />版本</el-button
        >
      </div>
      <div>
        <div v-if="processSortData.length > 0">
          <DraggableList
            :list="processSortData"
            :selectable="true"
            ref="processDragListref"
            @select="onDragProcess()"
          >
            <template #item="{ item }">
              <DraggableListItem
                :item="item"
                :class="{
                  active: item.id === processSelectData.id,
                }"
                :hasControl="false"
                style="border: none"
              >
                <div
                  @click="onClickProcess(item)"
                  class=".process-manage-dragitem"
                >
                  <div class="process-manage-dragitem-name">
                    {{ item.name }}
                  </div>
                  <div @click="onDeleteProcess(item)">
                    <i class="el-icon-delete processOper" />
                  </div>
                  <div @click="onEditProcess(item)">
                    <i class="el-icon-setting processOper" />
                  </div>
                  <div
                    class="control drag drag-handle processOper"
                    style="width: 20px"
                  >
                    <i class="el-icon-sort" />
                  </div>
                </div>
              </DraggableListItem>
            </template>
          </DraggableList>
        </div>
        <div v-else class="empty-data">
          <span>暂无数据</span>
        </div>
      </div>
    </div>
    <!--步骤编辑弹窗-->
    <el-dialog
      title="编辑"
      :visible.sync="editProcessDialogVisible"
      width="30%"
    >
      <FormRender
        :schema="processSchema"
        :data="processSelectData"
        :config="processFormConf"
        v-if="editProcessDialogVisible"
      />
      <span slot="footer">
        <el-button type="primary" @click="onEditProcessSubmit">保存</el-button>
      </span>
    </el-dialog>
    <!--步骤新增弹窗-->
    <el-dialog title="新增" :visible.sync="addProcessDialogVisible" width="30%">
      <FormRender
        :schema="processSchema"
        :data="processSelectData"
        :config="processFormConf"
        v-if="addProcessDialogVisible"
      />
      <span slot="footer">
        <el-button type="primary" @click="onAddProcessSubmit">保存</el-button>
      </span>
    </el-dialog>
    <!--版本选择-->
    <el-dialog
      title="版本"
      :visible.sync="versionSelectedDialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <SchemaTable
        v-if="versionSelectedDialogVisible"
        :hasQuery="false"
        :hasAction="true"
        :canCreate="false"
        ref="schematablerefVersion"
        :schema="versionSelectedSchema"
        :model="versionSelectedModel"
      >
        <template #actionBtns="{ row }">
          <el-link
            v-if="row.activated != 1"
            type="primary"
            class="action-link"
            @click="switchActiveVersion(row)"
          >
            切换
          </el-link>
          <el-link
            type="primary"
            class="action-link"
            @click="goVersionCopy(row)"
          >
            复制
          </el-link>
          <el-link
            v-if="row.activated != 1"
            type="primary"
            class="action-link"
            @click="deleteVersion(row)"
          >
            删除
          </el-link>
        </template>
      </SchemaTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onVersionSelectedDialog(false)">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style scoped>
.dotdotdot {
  display: inline-block;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.edit-btn {
  margin-left: 10px;
}

.el-card__header {
  border: none;
  padding-bottom: 0;
}
.empty-data {
  height: 360px;
  line-height: 360px;
  text-align: center;
  color: #999;
}
.processOper {
  float: right;
  line-height: 40px;
  text-align: center;
  width: 20px;
}
.active {
  background: rgba(245, 247, 250, 1);
  color: rgba(0, 110, 187, 1);
}

.control.drag {
  width: 20px;
}

.process-manage {
  /* border-right: 1px #dcdfe6 solid; */
  /* min-height: 400px; */
}
.process-manage-header {
  border-bottom: 1px #dcdfe6 solid;
  height: 40px;
  padding: 10px 20px;
}
.process-manage-dragitem {
  height: 40px;
  font-size: 16px;
  text-align: center;
}
.process-manage-dragitem-name {
  line-height: 40px;
  float: left;
  padding-left: 15px;
  width: 60%;
}
</style>

<script>
import axios from "axios";
import { uris, attachId } from "./../../api/uri";

import { SchemaTable, DataModel } from "enn-schema-table";
import FormRender from "enn-schema-form-render";

import processSchema from "../process/process.schema.json";
import { DraggableList, DraggableListItem } from "enn-draggable-list";
import Procedure from "../procedure/index.vue";

import versionSelectedSchema from "./version.schema.json";

export default {
  props: {
    groupId: {
      type: String,
    },
    workflowId: {
      type: String,
    },
  },
  components: {
    SchemaTable,
    FormRender,
    DraggableList,
    DraggableListItem,
    Procedure,
  },
  data() {
    return {
      processSortData: [], // 步骤排序数据
      processSelectData: {}, // 当前选中，操作的数据

      selectProcessId: "", // 工序组件需要的步骤id
      selectWorkflowId: null, //工序组件需要的制程id
      selectGroupId: this.groupId,

      processSchema,
      editProcessDialogVisible: false,
      addProcessDialogVisible: false,
      processFormConf: {
        getOptions: this.getDependProcessOption.bind(this),
      },
      versionSelectedSchema,
      versionSelectedDialogVisible: false, // 版本选择
      versionSelectedModel: new DataModel({
        getListApi: `${uris.workflowByGroup}`,
        getListMap: (item) => {
          item.createdTime = +new Date(item.created_at);
          if (item.activated == 1) {
            item.version = item.version + "（当前使用版本）";
          }
          return item;
        },
        query: {
          pageNumber: 1,
          pageSize: 100,
          groupId: this.groupId ? this.groupId : "0",
        },
      }),
    };
  },
  created() {
    this.getActivedWorkflow();
    this.getWorkflowProcess();
  },
  methods: {
    getActivedWorkflow(){
       this.versionSelectedModel.getList().then((res)=>{
        const list = res.list;
        list.map((item)=>{
          if(1 === item.activated){
            this.selectWorkflowId = item.id;
          }
        });
            
       });
    },
    getWorkflowProcess() {
      axios
        .get(`${uris.processByActiveWorkflow}?groupId=${this.groupId}`)
        .then((res) => {
          const {
            data: {
              data: { list: processlist },
            },
          } = res;
          this.processSortData = processlist.map((item) => {
            return _.pick(item, ["id", "sortIndex", "name", "dependOnId"]);
          });
          if (this.processSortData.length > 0) {
            this.selectProcessId = this.processSortData[0].id;
            this.processSelectData = this.processSortData[0];
            this.$emit("processDataLen", this.processSortData.length);
            this.$emit("processClicked", this.processSortData[0].id);
          } else {
            this.$emit("processDataLen", 0);
          }
        });
    },
    getDependProcessOption() {
      // 获取依赖步骤远程选项
      return axios
        .get(`${uris.processByActiveWorkflow}?groupId=${this.groupId}`)
        .then((res) => {
          const {
            data: {
              data: { list: prcessList },
            },
          } = res;
          const processOptions = prcessList.map((item) => {
            return { value: item.id, label: item.name };
          });
          return processOptions;
        });
    },
    onEditProcess(processSortItem) {
      this.editProcessDialogVisible = true;
    },
    onClickProcess(item) {
      // 点击，更新工序列表数据
      const processId = item.id;
      this.selectProcessId = processId;
      this.processSelectData = item;
      this.$emit("processClicked", item.id);
    },
    async onEditProcessSubmit() {
      const { id: processId, dependOnId, name } = this.processSelectData;
      const res = await axios.put(`${uris.process}/${processId}`, {
        dependOnId,
        name,
      });
      this.$message({
        type: res.data.code !== 0 ? "error" : "success",
        message: res.data.code !== 0 ? res.data.message : "修改成功",
      });
      this.getWorkflowProcess();
      this.editProcessDialogVisible = false;
      this.processSelectData = {};
    },
    async onDeleteProcess(processItem) {
      const res = await axios.delete(`${uris.process}/${processItem.id}`);
      this.$message({
        type: res.data.code !== 0 ? "error" : "success",
        message: res.data.code !== 0 ? res.data.message : "删除成功",
      });
      this.getWorkflowProcess();
    },
    onAddProcess() {
      this.addProcessDialogVisible = true;
      this.processSelectData = {};
    },
    async onAddProcessSubmit() {
      const { dependOnId, name } = this.processSelectData;
      const res = await axios.post(uris.process, {
        dependOnId,
        name,
        workflowId: this.selectWorkflowId,
      });
      this.$message({
        type: res.data.code !== 0 ? "error" : "success",
        message: res.data.code !== 0 ? res.data.message : "新增成功",
      });
      this.getWorkflowProcess();
      this.addProcessDialogVisible = false;
      this.processSelectData = {};
    },
    onDragProcess() {
      // 拖拽的时候即进行修改请求
      axios
        .put(`${uris.processSort}`, {
          sortData: this.processSortData,
          workflowId: this.selectWorkflowId,
        })
        .then((res) => {
          this.$message({
            type: res.data.code !== 0 ? "error" : "success",
            message: res.data.code !== 0 ? res.data.message : "已更新",
          });
        });
    },
    onVersionSelectedDialog(oper) {
      this.versionSelectedDialogVisible = oper;
    },
    switchActiveVersion(row) {
      axios
        .post(`${uris.workflowActived}`, {
          workflowId: row.id,
          version: Number(row.version.replace("v", "").replace(".", "")),
          groupId: row.groupId,
        })
        .then((res) => {
          if (res.data.code !== 0) {
            this.$message({
              type: "error",
              message: res.data.message,
            });
            return;
          }
          this.$message({
            type: "success",
            message: "版本切换成功",
          });
          this.selectWorkflowId = row.id;
          this.groupId = row.groupId;
          this.getWorkflowProcess();
          this.flushWorkVersionList();
        });
    },
    goVersionCopy(row) {
      axios
        .post(`${uris.workflowCopy}`, {
          workflowId: row.id,
        })
        .then((res) => {
          if (res.data.code !== 0) {
            this.$message({
              type: "error",
              message: res.data.message,
            });
            return;
          }
          this.$message({
            type: "success",
            message: "新版本复制完成",
          });
          this.getWorkflowProcess();
          this.flushWorkVersionList();
        });
    },
    deleteVersion(row) {
      axios.delete(`${uris.workflow}/${row.id}`, {}).then((res) => {
        if (res.data.code !== 0) {
          this.$message({
            type: "error",
            message: res.data.message,
          });
          return;
        }
        this.$message({
          type: "success",
          message: "版本删除成功",
        });
        this.getWorkflowProcess();
        this.flushWorkVersionList();
      });
    },
    flushWorkVersionList() {
      if (Array.isArray(this.$refs.schematablerefVersion)) {
        this.$refs.schematablerefVersion[0].onSearch();
      } else {
        this.$refs.schematablerefVersion.onSearch();
      }
    },
  },
};
</script>
