<template>
  <div>
    <Breadcrumb
      :home="false"
      :breadcrumbs="[
        {
          label: '制程管理',
          to: '/workflow',
        },
        {
          label: '详情',
          to: `/workflow/detail?workflowId=${this.$route.query.workflowId}&groupId=${this.$route.query.groupId}`,
        },
      ]"
    />
    <el-card class="card-common">
      <template #header>基本信息</template>
      <InfoPanel :schema="workflowSchema" :data="workflow">
        <template #name="{ value }">
          <div>{{ value }}</div>
          <el-link type="primary" class="edit-btn" @click="onEdit">
            编辑
          </el-link>
        </template>
      </InfoPanel>
      <el-dialog title="编辑" :visible.sync="editDialogVisible" width="30%">
        <el-form :model="factory" label-width="80px">
          <el-form-item label="请输入制程名称" style="margin: 0">
            <el-input v-model="workflow.name" />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="onEditSubmit">提交</el-button>
        </span>
      </el-dialog>
    </el-card>
    <el-card class="card-common">
      <template #header>步骤管理</template>
      <div class="process-wall">
        <el-row type="flex" :gutter="20">
          <el-col
            :span="6"
            class="vertical-dividing-line"
            style="padding-right: 0px"
          >
            <ProcessActived
              :groupId="selectGroupId"
              :workflowId="selectWorkflowId"
              @processClicked="onProcessClicked"
              @processDataLen="onProcessDataLen"
            ></ProcessActived>
          </el-col>
          <el-col :span="18">
            <div v-if="processDataLen > 0" style="padding:30px;20px">
              <Procedure
                :processId="selectProcessId"
                :workflowId="selectWorkflowId"
                :groupId="selectGroupId"
              />
            </div>
            <div v-else class="empty-data">
              <span>暂无数据</span>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<style scoped>
.dotdotdot {
  display: inline-block;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.edit-btn {
  margin-left: 10px;
}

.el-card__header {
  border: none;
  padding-bottom: 0;
}
.empty-data {
  height: 360px;
  line-height: 360px;
  text-align: center;
  color: #999;
}
.card-common {
  margin-top: 20px;
}
.process-wall {
  border: 1px solid #dcdfe6;
}
.vertical-dividing-line {
  border-right: 1px solid #dcdfe6;
}
</style>

<script>
import axios from "axios";
import { uris, attachId } from "./../../api/uri";

import InfoPanel from "enn-info-panel";
import workflowSchema from "./workflow-detail.schema.json";

import { SchemaTable, DataModel } from "enn-schema-table";
import FormRender from "enn-schema-form-render";
import { DraggableList, DraggableListItem } from "enn-draggable-list";
import Procedure from "../procedure/index.vue";
import Breadcrumb from "enn-breadcrumb";

import ProcessActived from "../process/process-sort.vue";

export default {
  components: {
    InfoPanel,
    SchemaTable,
    FormRender,
    DraggableList,
    DraggableListItem,
    Procedure,
    Breadcrumb,
    ProcessActived,
  },
  data() {
    const groupId = this.$route.query.groupId;
    const workflowId = this.$route.query.workflowId;
    return {
      envTypeMap: {
        1: "正式",
        2: "测试",
      },
      workflowSchema,
      workflow: {},

      editDialogVisible: false,

      processSortData: [], // 步骤排序数据
      processSelectData: {}, // 当前选中，操作的数据

      selectProcessId: "", // 工序组件需要的步骤id
      selectWorkflowId: workflowId, //工序组件需要的制程id
      selectGroupId: groupId,

      factory: null,
      processDataLen: 0,
    };
  },
  created() {
    this.getWorkflow();
  },
  methods: {
    getWorkflow() {
      axios.get(`${uris.workflow}/${this.selectWorkflowId}`).then((res) => {
        const {
          data: { data: workflow },
        } = res;
        console.log(res);
        workflow.product_name = workflow.product?.name;
        workflow.id = this.selectWorkflowId;
        workflow.envType = this.envTypeMap[workflow.envType + ""]
          ? this.envTypeMap[workflow.envType + ""]
          : "未知类型";
        this.workflow = workflow;
      });
    },

    onEdit() {
      this.editDialogVisible = true;
    },
    onEditSubmit() {
      axios
        .put(`${uris.workflow}/${this.selectWorkflowId}`, {
          name: this.workflow.name,
        })
        .then((res) => {
          this.$message({
            type: res.data.code !== 0 ? "error" : "success",
            message: res.data.code !== 0 ? res.data.message : "修改成功",
          });
          this.editDialogVisible = false;
        });
    },
    onProcessClicked(processId) {
      this.selectProcessId = processId;
    },
    onProcessDataLen(length) {
      this.processDataLen = length;
    },
  },
};
</script>
